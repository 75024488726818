import React from "react";
import { ReactComponent as OakLogo } from "./oak-logo.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as LinkedIn } from "./linkedin.svg";

const Finisher: React.FC = () => {
  return (
    <div className="flex flex-col md:flex-row p-4 m-4">
      <div className="hidden md:flex flex-col justify-center">
        <div className="flex">
          <OakLogo className="w-12 h-12" />
          <div className="text-4xl">Oak</div>
        </div>
        <div className="text-xl ml-4 mt-2">
          <a href="https://teamblackbox.in/" target="_blank" rel="noreferrer">
            © TeamBlackBox Private Limited 2023
          </a>
        </div>
      </div>
      <div className="grow sm:hidden md:flex" />
      <div className="flex flex-col items-center md:items-end md:text-right gap-2">
        <div className="flex justify-end">
          <a
            href="https://www.instagram.com/oakapp.in/"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram className="h-8 w-8" />
          </a>
          <a
            href="https://www.linkedin.com/company/team-black-box/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn className="h-8 w-8" />
          </a>
        </div>
        <a href="/privacy" target="_blank" rel="noreferrer" className="text-xl">
          Privacy Policy
        </a>
        <a href="/terms" target="_blank" rel="noreferrer " className="text-xl">
          Terms &amp; Conditions
        </a>
      </div>
      <div className="flex md:hidden flex-col items-center gap-4">
        <div className="text-xl ml-4 mt-2">
          © TeamBlackBox Private Limited 2024
        </div>

        <div className="flex">
          <OakLogo className="w-12 h-12" />
          <div className="text-4xl">Oak</div>
        </div>
      </div>
    </div>
  );
};

export default Finisher;
