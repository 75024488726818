import React from "react";

const FeatureHeader: React.FC = () => {
  return (
    <div className="flex justify-center mt-2 mb-6" id="features">
      <div className="text-slate-700 border-b-4 border-blue-500 text-2xl px-4 py-2">
        Features
      </div>
    </div>
  );
};

export default FeatureHeader;
