import React from "react";

interface DescriptionProps {
  title: string;
  subHeading?: string;
}

interface ImageProps extends DescriptionProps {
  image: any;
}

interface FeatureProps extends ImageProps {
  reverseOrientation?: boolean;
}

const Description: React.FC<DescriptionProps> = ({ title, subHeading }) => {
  return (
    <div className="flex flex-col px-8 md:px-6 lg:px-10 xl:px-20 justify-center">
      <div className="text-2xl md:text-xl lg:text-3xl xl:text-4xl font-semibold">
        {title}
      </div>
      <div className="text-lg md:text-lg lg:text-2xl font-thin mt-2 leading-normal">
        {subHeading}
      </div>
    </div>
  );
};

const Image: React.FC<ImageProps> = ({ image, title }) => {
  return (
    <img src={image} className="object-cover aspect-video w-full" alt={title} />
  );
};

const Feature: React.FC<FeatureProps> = ({
  title,
  subHeading,
  image,
  reverseOrientation,
}) => {
  return (
    <>
      <div className="hidden md:grid grid-cols-2">
        {reverseOrientation ? (
          <>
            <Image image={image} title={title} />
            <Description title={title} subHeading={subHeading} />
          </>
        ) : (
          <>
            <Description title={title} subHeading={subHeading} />
            <Image image={image} title={title} />
          </>
        )}
      </div>
      <div className="flex flex-col gap-8 md:hidden mt-8 md:mt-0">
        <Description title={title} subHeading={subHeading} />
        <Image image={image} title={title} />
      </div>
    </>
  );
};

export default Feature;
