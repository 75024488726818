import React from "react";
import { ReactComponent as OakLogo } from "./oak-logo.svg";

const Finisher: React.FC = () => {
  return (
    <div className="flex flex-col text-center items-center p-4 bg-zinc-100">
      <OakLogo className="w-16 h-16 lg:w-24 lg:h-24 my-6" />
      <div className="text-3xl lg:text-4xl mx-10 md:mx-24 mb-8 font-thin">
        Oak is the easiest way to manage workers, operations, inventory and
        finances of your estate/plantation
      </div>
    </div>
  );
};

export default Finisher;
