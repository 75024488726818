import React from "react";
import Highlight from "./Highlight";
import Feature from "./Feature";
import Header from "./Header";
import Workers from "./workers.jpg";
import Activities from "./activities.jpg";
import RollCall from "./rollcall.jpg";
import Soil from "./soil.jpg";
import Finances from "./finances.jpg";
import Inventory from "./inventory.jpg";
import Grow from "./grow.jpg";
import Land from "./land.jpg";
import Finisher from "./Finisher";
import Contact from "./Contact";
import FeatureHeader from "./FeatureHeader";

function App() {
  return (
    <div className="container mx-auto bg-white text-slate-900 h-full">
      <Header />
      <Highlight />
      <FeatureHeader />
      <Feature
        title="Workers, Groups &amp; Supervisors"
        subHeading="Manage worker profiles with customizable wages, contact details, payment schedules, and advances and loans given to workers."
        image={Workers}
      />
      <Feature
        title="Activities"
        subHeading="Describe the work done using comprehensive activities (like pruning and weeding) inclusive of wage settings, checklists, and automated smart reminders."
        image={Activities}
        reverseOrientation
      />
      <Feature
        title="Land Management"
        subHeading="Divide your estate into smaller sections via GPS tagging. Track rainfall, soil health, activities performed, plants grown and yield from each section."
        image={Land}
      />
      <Feature
        title="Roll Call &amp; Activity Tracking"
        subHeading="Record attendance of workers daily and track work done by them  and specialized worker groups on different sections."
        image={RollCall}
        reverseOrientation
      />
      <Feature
        title="Finances. Automated."
        subHeading="Automatically generate expense reports, record payments and income, manage accounts, estate loans, and get insights with our year-to-date review reports."
        image={Finances}
      />
      <Feature
        title="Soil Health &amp; Fertilizers"
        subHeading="Record soil health metrics for each section and let Oak automatically calculate fertilizer concentration and mixes."
        image={Soil}
        reverseOrientation
      />
      <Feature
        title="Inventory Management"
        subHeading="Manage processing workflow of your crops, sales of the produce, track consumables, and record tools purchases &amp; machinery upgrades"
        image={Inventory}
      />
      <Feature
        title="Grow your Estate"
        subHeading="Create harvest scenarios based on historic yield data and commodity pricing trends. Create goals for equipment purchase/construction works linked to your finances. Explore alternative revenue streams."
        image={Grow}
        reverseOrientation
      />
      <Finisher />
      <Contact />
    </div>
  );
}

export default App;
