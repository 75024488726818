import React from "react";
import FeatureImage from "./highlight.jpg";
import "./highlight.css";

const Highlight: React.FC = () => {
  return (
    <div className="relative text-white">
      <img
        src={FeatureImage}
        alt="Coffee Harvesting"
        className="object-cover aspect-video w-full"
      />
      <div className="absolute top-0 left-0 text-2xl feature-text w-full h-full">
        <div className="absolute p-8" style={{ bottom: "36%" }}>
          <div className="flex flex-col gap-2 lg:gap-4 ml-0 md:mx-12">
            <div className="text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-semibold">
              Estate Management Simplified
            </div>
            <div className="text-lg xs:text-1xl sm:text-2xl md:text-3xl lg:text-4xl font-thin">
              Save Time. Reduce Costs. Grow Your Estate.
            </div>
            <div className="hidden md:flex mt-2">
              <a
                href="https://forms.gle/gj3aLAmtzK4cmHxi9"
                target="_blank"
                rel="noreferrer"
              >
                <div className="bg-white text-slate-700 shrink text-base md:text-2xl px-4 pt-2 pb-3 rounded-lg cursor-pointer">
                  Request early access
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlight;
