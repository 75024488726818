import React from "react";
import { ReactComponent as OakLogo } from "./oak-logo.svg";

const Header: React.FC = () => {
  return (
    <div className="bg-white sticky top-0 py-3 px-2 flex flex-row items-center z-10">
      <div className="flex flex-row justify-center items-center gap-1">
        <OakLogo className="w-8 h-8 xs:w-12 xs:h-12" />
        <div className="text-2xl xs:text-4xl">Oak</div>
      </div>
      <div className="grow" />
      <div className="flex flex-row gap-4 text-lg xs:text-xl items-center">
        <div className="hidden md:inline">
          <a
            href="https://teamblackbox.in/"
            target="_blank"
            rel="noreferrer"
            className="hover:shadow-xl px-4 pt-2 pb-3 rounded-lg"
          >
            About Us
          </a>
        </div>
        <div className="hidden md:inline">
          <a
            href="#features"
            className="hover:shadow-xl px-4 pt-2 pb-3 rounded-lg"
          >
            Features
          </a>
        </div>
        <a
          href="https://forms.gle/gj3aLAmtzK4cmHxi9"
          target="_blank"
          rel="noreferrer"
        >
          <div className="bg-blue-500 text-white px-4 pt-2 pb-3 rounded-lg cursor-pointer">
            Request early access
          </div>
        </a>
      </div>
    </div>
  );
};

export default Header;
